import { configApi } from '../http-client.js'

export const getIpWhitelists = () => configApi.get('/api/Whitelist/DB/ApplicationIPs').then(({ data }) => ({
	...data
}))

export const upsertIpWhitelist = ipWhitelist => {
	return configApi.post('/api/Whitelist/DB/ApplicationIPs/Upsert', ipWhitelist)
}

export const deleteIpWhitelist = id => {
	return configApi.delete(`/api/Whitelist/DB/ApplicationIPs/${id}`)
}
