<template>
	<div class="cassie-vertical-md">
		<div class="d-flex align-center">
			<v-spacer />
			<PrimaryActionButton
				v-if="!readOnly"
				@click="showIpWhitelistingForm = true"
			>
				<v-icon left>
					mdi-plus
				</v-icon>
				Create
			</PrimaryActionButton>
		</div>
		<SectionCard>
			<template #title>
				<slot name="card-title">
					IP Whitelisting
				</slot>
			</template>
			<template #body>
				<div class="cassie-horizontal-lg">
					<DataTable
						:headers="tableHeaders"
						:items="ipWhitelists"
						sort-by="id"
						sort-desc
						@click:row="editIpWhitelist"
					>
						<template #item.action="{ item }">
							<IconButton
								tooltip-text="Edit IP Whitelist"
								@click="editIpWhitelist(item)"
							>
								{{ readOnly ? 'mdi-eye' : 'mdi-pencil' }}
							</IconButton>
							<IconButton
								v-if="!readOnly"
								tooltip-text="Delete IP Whitelist"
								@click.stop.prevent="ipWhitelistIdToBeRemoved = item.id"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</div>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="ipWhitelistIdToBeRemoved"
			:entity-name="ipWhitelistToBeRemoved"
			entity-type="IP Whitelist"
			@close="ipWhitelistIdToBeRemoved = null"
			@delete="onDeleteIpWhitelist"
		/>
		<ManageWhitelistingModal
			v-if="showIpWhitelistingForm && !readOnly"
			:ip-whitelist-to-edit="ipWhitelistToEdit"
			:type-id="typeId"
			@close="cancelForm"
			@submit="upsertIpWhitelist"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionCard from './section-card.vue'
import PrimaryActionButton from './primary-action-button.vue'
import IconButton from './icon-button.vue'
import DataTable from './data-table.vue'
import ManageWhitelistingModal from '../../admin-portal/src/components/views/administration/manage-whitelisting-modal.vue'
import { showSnackbar } from '../state/snackbar.js'
import { upsertIpWhitelist, deleteIpWhitelist } from '../utils/api/whitelisting.js'
import ConfirmDeleteModal from './confirm-delete-modal.vue'
import { CAN_CREATE_AND_UPDATE_WHITELIST } from '../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		ConfirmDeleteModal,
		SectionCard,
		PrimaryActionButton,
		DataTable,
		IconButton,
		ManageWhitelistingModal
	},
	props: {
		ipWhitelists: {
			type: Array,
			default: () => []
		},
		typeId: Number
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			showIpWhitelistingForm: false,
			ipWhitelistIdToBeRemoved: null,
			ipWhitelistToEdit: null
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		tableHeaders () {
			return [
				{
					text: 'ID',
					value: 'id',
					width: '10%'
				},
				{
					text: 'IP Range',
					value: 'ipRange',
					width: '30%'
				},
				{
					text: 'Description',
					value: 'description',
					width: '45%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '15%',
					sortable: false
				}
			]
		},
		ipWhitelistToBeRemoved () {
			return this.ipWhitelists.find(({ id }) => id === this.ipWhitelistIdToBeRemoved)?.ipRange
		},
		readOnly () {
			return !this.checkPermission(CAN_CREATE_AND_UPDATE_WHITELIST)
		}
	},
	methods: {
		cancelForm () {
			this.showIpWhitelistingForm = false
			this.ipWhitelistToEdit = null
		},
		editIpWhitelist (whiteList) {
			if (!this.readOnly) {
				this.ipWhitelistToEdit = whiteList
				this.showIpWhitelistingForm = true
			}
		},
		async upsertIpWhitelist (ipWhitelist) {
			await upsertIpWhitelist(ipWhitelist)
			showSnackbar('Ip Whitelist added successfully')
			this.ipWhitelistToEdit = null
			this.showIpWhitelistingForm = false
			this.$emit('submit')
		},
		async onDeleteIpWhitelist () {
			await deleteIpWhitelist(this.ipWhitelistIdToBeRemoved)
			this.ipWhitelistIdToBeRemoved = null
			this.$emit('submit')
			showSnackbar('Ip Whitelist has been deleted')
		}
	}
}
</script>
