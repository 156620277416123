<template>
	<ViewLayout>
		<template #header-title>
			Whitelisting
		</template>
		<template #header-caption>
			Add, edit and manage whitelisting
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<IpWhitelisting
						:type-id="apiType"
						:ip-whitelists="apiWhitelist"
						@submit="loadIpWhitelists"
					>
						<template #card-title>
							IP Whitelisting - API
						</template>
					</IpWhitelisting>
				</template>
				<template #1>
					<IpWhitelisting
						:type-id="adminPortalType"
						:ip-whitelists="adminPortalWhitelist"
						@submit="loadIpWhitelists"
					>
						<template #card-title>
							IP Whitelisting - Admin Portal
						</template>
					</IpWhitelisting>
				</template>
				<template #2>
					<IpWhitelisting
						:type-id="csPortalType"
						:ip-whitelists="csPortalWhitelist"
						@submit="loadIpWhitelists"
					>
						<template #card-title>
							IP Whitelisting - CS Portal
						</template>
					</IpWhitelisting>
				</template>
			</TabItems>
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import { getIpWhitelists } from '../../../../../shared/utils/api/whitelisting.js'
import IpWhitelisting from '../../../../../shared/components/ip-whitelisting.vue'
import Tabs from '../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../shared/components/tab-items.vue'

export default {
	components: { IpWhitelisting, ViewLayout, Tabs, TabItems },
	data () {
		return {
			tabs: [{ title: 'API', slot: '0' }, { title: 'Admin Portal', slot: '1' }, { title: 'CS Portal', slot: '2' }],
			tab: '0',
			adminPortalWhitelist: [],
			csPortalWhitelist: [],
			apiWhitelist: [],
			typeIdLookup: [],
			adminPortalType: 1,
			csPortalType: 2,
			apiType: 3
		}
	},
	created () {
		this.loadIpWhitelists()
	},
	methods: {
		async loadIpWhitelists () {
			const { adminPortal, csPortal, api } = await getIpWhitelists()
			this.adminPortalWhitelist = adminPortal
			this.csPortalWhitelist = csPortal
			this.apiWhitelist = api
		}
	}
}
</script>
