<template>
	<ValidationForm
		#default="{ handleSubmit }"
	>
		<Modal
			width="650px"
		>
			<template #modal-title>
				{{ modalTitle }}
				<v-spacer />
				<IconButton
					@click="$emit('close')"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="ipRange.ipRange"
								label="IP Range*"
								tooltip-text="This field can be the source ip address, or a range such as 65.34.77.0 - 65.34.77.9 or 65.34.77.0/28"
								:rules="{ipRange: true, required: true}"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Textarea
								v-model="ipRange.description"
								label="IP Range Description"
								no-resize
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import Modal from '../../../../../shared/components/modal.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Textarea from '../../../../../shared/components/textarea.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'

export default {
	components: {
		ValidationForm,
		Modal,
		IconButton,
		TextField,
		Textarea,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		ipWhitelistToEdit: Object,
		typeId: Number
	},
	data () {
		return {
			ipRange: JSON.parse(JSON.stringify(this.ipWhitelistToEdit || {
				ipRange: '',
				description: ''
			}))
		}
	},
	computed: {
		isEdit () {
			return !!this.ipWhitelistToEdit?.id
		},
		modalTitle () {
			return this.isEdit ? 'Edit IP Whitelist' : 'Add IP Whitelist'
		}
	},
	methods: {
		submit () {
			const ipWhitelist = {
				...this.ipRange,
				id: this.ipWhitelistToEdit?.id || null,
				typeId: this.typeId
			}
			this.$emit('submit', ipWhitelist)
		}
	}
}
</script>
